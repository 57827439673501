<template>
  <div class="admin">
    <Header></Header>
    <Aside></Aside>
    <el-main class="main">
      <el-tabs v-if="adminState === 'articleEditing'" v-model="tabsValue" type="border-card"  class="config">
        <div style="width: 90%; height: 50px; margin: auto">
          <div style="float: left">
            <el-button @click="returnList('articleList')">返回列表</el-button>
            <el-button @click="setTabsValue('edit')">测试</el-button>
          </div>
        </div>
        <el-tab-pane v-if="tabsValue === 'edit'" label="编辑文章" name="edit">
          <ArticleEdit :edit="true" :id="1"></ArticleEdit>
        </el-tab-pane>
        <el-tab-pane v-if="tabsValue === 'add'" label="添加文章" name="add">
          <ArticleEdit></ArticleEdit>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-if="adminState === 'categoryEditing'" v-model="tabsValue" type="border-card"  class="config">
        <div style="width: 90%; height: 50px; margin: auto">
          <div style="float: left">
            <el-button @click="returnList('categoryList')">返回列表</el-button>
            <el-button @click="setTabsValue('edit')">测试</el-button>
          </div>
        </div>
        <el-tab-pane v-if="tabsValue === 'edit'" label="编辑分类" name="edit">
          <CategoryEdit :edit="true" :id="1"></CategoryEdit>
        </el-tab-pane>
        <el-tab-pane v-if="tabsValue === 'add'" label="添加分类" name="add">
          <CategoryEdit></CategoryEdit>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-if="adminState === 'main'" type="border-card"  class="config"  v-model="tabsValue">
        <el-tab-pane label="全部列表" name="articleList">
          <ArticleList></ArticleList>
        </el-tab-pane>
        <el-tab-pane label="分类管理" name="categoryList">
          <CategoryList></CategoryList>
        </el-tab-pane>
        <el-tab-pane label="横幅图片">
          <img width="800px" v-if="section_now.banner !== ''" :src="section_now.banner">
          <el-upload
              class="upload-demo"
              :file-list="file_list"
              action="#"
              :auto-upload="false"
              :on-change="handleChange">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <el-button type="primary" style="margin: 20px 40px;" @click="upload()">保存</el-button>
        </el-tab-pane>

      </el-tabs>

      <el-tabs v-if="adminState === 'videoList'" v-model="tabsValue" type="border-card"  class="config" >
        <el-tab-pane label="视频"  name="videoList">
          <VideoListEdit></VideoListEdit>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-if="adminState === 'videoEditing'" v-model="tabsValue" type="border-card"  class="config" >
        <div style="width: 90%; height: 50px; margin: auto">
          <div style="float: left">
            <el-button
                @click="setAdminState('videoList'); setTabsValue('videoList')"
            >返回列表</el-button>
          </div>
        </div>
        <el-tab-pane v-if="tabsValue === 'edit'" label="编辑视频" name="edit">
          <VideoEdit :edit="true" :id="1"></VideoEdit>
        </el-tab-pane>
        <el-tab-pane v-if="tabsValue === 'add'" label="添加视频" name="add">
          <VideoEdit></VideoEdit>
        </el-tab-pane>
      </el-tabs>

      <el-tabs v-if="adminState === 'bannerList'" v-model="tabsValue" type="border-card"  class="config" >
        <el-tab-pane label="首页横幅"  name="bannerList">
          <BannerList></BannerList>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-if="adminState === 'bannerEditing'" v-model="tabsValue" type="border-card"  class="config" >
        <div style="width: 90%; height: 50px; margin: auto">
          <div style="float: left">
            <el-button
                @click="setAdminState('bannerList'); setTabsValue('bannerList')"
            >返回列表</el-button>
          </div>
        </div>
        <el-tab-pane v-if="tabsValue === 'edit'" label="编辑首页横幅" name="edit">
          <BannerEdit :edit="true"></BannerEdit>
        </el-tab-pane>
        <el-tab-pane v-if="tabsValue === 'add'" label="添加首页横幅" name="add">
          <BannerEdit></BannerEdit>
        </el-tab-pane>
      </el-tabs>

      <el-tabs v-if="adminState === 'carouselList'" v-model="tabsValue" type="border-card"  class="config" >
        <el-tab-pane label="首页走马灯"  name="carouselList">
          <CarouselList></CarouselList>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-if="adminState === 'carouselEditing'" v-model="tabsValue" type="border-card"  class="config" >
        <div style="width: 90%; height: 50px; margin: auto">
          <div style="float: left">
            <el-button
                @click="setAdminState('carouselList'); setTabsValue('carouselList')"
            >返回列表</el-button>
          </div>
        </div>
        <el-tab-pane v-if="tabsValue === 'edit'" label="编辑首页走马灯" name="edit">
          <CarouselEdit :edit="true"></CarouselEdit>
        </el-tab-pane>
        <el-tab-pane v-if="tabsValue === 'add'" label="添加首页走马灯" name="add">
          <CarouselEdit></CarouselEdit>
        </el-tab-pane>
      </el-tabs>


    </el-main>
  </div>
</template>

<script>
import Header from "@/views/admin/components/Header";
import Aside from "@/views/admin/components/Aside";
import axios from "axios";
import ArticleList from "@/views/admin/components/ArticleList";
import {mapMutations, mapState} from "vuex";
import ArticleEdit from "@/views/admin/components/ArticleEdit";
import CategoryList from "@/views/admin/components/CategoryList";
import CategoryEdit from "@/views/admin/components/CategoryEdit";
import VideoListEdit from "@/views/admin/components/VideoListEdit";
import VideoEdit from "@/views/admin/components/VideoEdit";
import BannerList from "@/views/admin/components/BannerList";
import CarouselList from "@/views/admin/components/CarouselList";
import BannerEdit from "@/views/admin/components/BannerEdit";
import CarouselEdit from "@/views/admin/components/CarouselEdit";
export default {
  name: "admin",
  components: {
    CarouselEdit,
    BannerEdit,
    CarouselList,
    BannerList,
    VideoEdit,
    VideoListEdit,
    CategoryEdit,
    CategoryList,
    ArticleEdit,
    ArticleList,
    Aside,
    Header
  },
  data(){
    return {
      banner:{},
      file_list:[],
    }
  },
  computed: {
    ...mapState([
        'section_now',
        'section_list',
        'article_editing',
        // 'isEditing',
        'adminState',
    ]),
    tabsValue: {
      get(){return this.$store.state.tabsValue},
      set(value){this.setTabsValue(value)}
    },
  },
  mounted() {
    this.initData();
  },
  methods:{
    ...mapMutations([
        'initData',
        'setAdminState',
        'setTabsValue',
        'setSectionNow',
    ]),
    handleChange(file){
      this.banner = file.raw;
      this.file_list.push(file);
    },
    upload(){
      const formData = new FormData()
      formData.append('banner', this.banner);
      axios({
        url:"/api/document/admin/section/edit/"+this.section_now.sectionId+"/",
        method:"put",
        data:formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res)=>{
        // console.log('articles', res);
        this.setSectionNow(this.section_now);
      })
    },
    returnList(tabsValue){
      this.setAdminState('main');
      this.setTabsValue(tabsValue)
    }
  }
}
</script>

<style scoped>
.admin{
  width: 100%;
  height: 90vh;
  min-width: 1200px;
}
.main {
  //background-color: #E9EEF3;
  //float: right;
  margin: auto;
  width: calc(100% - 200px);
  //height: 100%;
  //min-height: 90vh;
}
.config{
  float: top;
  margin: 20px auto;
  width: 95%;
  min-height: 80vh;
  //height: 850px;
  //background-color: #00feff;
}
</style>
