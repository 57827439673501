<template>
  <div class="aside">
      <el-menu
          default-active="1-0"
          class="el-menu-vertical-demo menu"
          @open="handleOpen"
          @close="handleClose">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>板块</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-for="(item, index) in section_list"
                :index="'1-'+index"
                @click="updateACList(item); setAdminState('main'); setTabsValue('articleList')"
            >
              <i class="el-icon-folder"></i>
              {{item.title}}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item
            index="2"
            @click="setAdminState('videoList'); setTabsValue('videoList')"
        >
          <i class="el-icon-video-camera"></i>
          <span>视频</span>
        </el-menu-item>
        <el-menu-item
            index="3"
            @click="setAdminState('bannerList'); setTabsValue('bannerList')"
        >
          <i class="el-icon-money"></i>
          <span>首页横幅图</span>
        </el-menu-item>
        <el-menu-item
            index="4"
            @click="setAdminState('carouselList'); setTabsValue('carouselList')"
        >
          <i class="el-icon-files"></i>
          <span>首页走马灯文章</span>
        </el-menu-item>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>网页模式</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-for="(item, index) in page_mode"
                :index="'5-'+index"
                @click="changePageMode(item);"
            >
              <i class="el-icon-folder"></i>
              {{item}}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions} from "vuex";
import axios from "axios";

export default {
  name: "Aside",
  data() {
    return {
      page_mode:['normal', 'grey']
    }
  },
  computed: {
    ...mapState([
      'section_list',
    ]),
  },
  methods: {
    ...mapActions([
      'updateACList',
    ]),
    ...mapMutations([
        'setSectionNow',
        'setAdminState',
        'setTabsValue',
    ]),
    changePageMode(mode) {
      axios({
        url:"/api/pageTools/admin/pageTools/"+mode+"/",
        method:"get",
      }).then((res)=>{
        alert(res);
      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    chooseSection(section){
      this.setSectionNow(section);
      context.commit('setSectionNow', section);
      context.commit('updateArticleList', section);
      context.commit('updateCategoryList', section);
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
.aside{
  float: left;
  height: calc(100vh - 52px);
  width: 200px;
}
.menu{
  height: 100%;
  text-align: left;
  //width: 100%;
}
</style>
