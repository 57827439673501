<template>
  <div>
    <div class="basesetting">
      <div class="baseitem">
        <div class="baseleft" style="margin-left: 70px;">文章：</div>
        <el-input style="max-width: 220px;margin-right: 10px;"
                  @change="changeId"
                  placeholder="输入文章ID"
                  v-model="carousel.article.articleId"></el-input>
        <el-autocomplete
            v-model="article"
            style="width: 100%"
            :fetch-suggestions="querySearchAsync"
            placeholder="通过文章标题检索"
            @select="handleSelect"
        ></el-autocomplete>
      </div>


      <div class="baseitem">
        <div class="baseleft" style="margin-left: 70px;"></div>
        <div style="color: #aaaaaa;font-size: 12px;margin-top: -5px;"  >可以直接输入文章ID，也可以通过文章标题搜索，最终以ID为准</div>
      </div>
      <div class="baseitem">
        <div class="baseleft" style="margin-left: 70px;">权重：</div>
        <el-input style="max-width: 220px;" v-model="carousel.weight"></el-input>
      </div>
      <div>
        <el-button @click="save" type="primary" style="margin-right: 100px;margin-top: 50px;">保存</el-button>
        <el-button @click="setAdminState('carouselList'); setTabsValue('carouselList')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "CarouselEdit",
  props:{
    edit:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      carousel: {
        article: {}
      },
      article:"",
    }
  },
  computed:{
    ...mapState([
      'carousel_editing'
    ]),
  },
  methods:{
    ...mapMutations([
      'setCarouselEditing',
      'setTabsValue',
      'setAdminState',
      'updateCarouselList'
    ]),
    save(){
      const formData = new FormData()
      formData.append('article',this.carousel.article.articleId);
      formData.append('weight',this.carousel.weight);

      if (this.edit) {
        this.axios({
          url: '/api/document/admin/carousel/edit/'+this.carousel.carouselId+'/',
          method: "put",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateCarouselList();
          this.setAdminState('carouselList');
          this.setTabsValue('carouselList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      } else {
        this.axios({
          url: '/api/document/admin/carousel/list/',
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateCarouselList();
          this.setAdminState('carouselList');
          this.setTabsValue('carouselList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      }
    },
    querySearchAsync(queryString, cb){
      this.axios.get('/api/document/user/article/search/?q='+queryString).then(res=>{
        console.log('search',res.data)
        for(let item of res.data){
          item.value = item.title
        }
        cb(res.data)
      })
    },
    handleSelect(item) {
      console.log(item);
      this.carousel.article.articleId = item.articleId
    },
    changeId(value){
      this.article = null;
      if(isNaN(value)){
        this.$message.error('请输入数字');
        return;
      }
      this.axios.get('/api/document/user/article/view/'+value).then(res=>{
        console.log(res)
        this.article = res.data.title
      }).catch(err=>{
        this.$message.error('ID为'+value+'的文章不存在');
      })
    }
  },
  mounted() {
    if(this.edit){ //
      this.axios.get(
          '/api/document/admin/carousel/edit/'+this.carousel_editing.carouselId+'/'
      ).then(res=>{
        this.carousel = res.data
      })
    }
  }
}
</script>

<style scoped>
.basesetting{
  /*border: solid 1px red;*/
  max-width: 1000px;
  margin: 10px auto;
}
.baseitem{
  margin-bottom: 15px;
  display: flex;
}
.baseleft{
  text-align: right;
  min-width: 50px;
  margin-top: 8px;
  margin-right: 5px;
}
.basemid{
  max-width: 705px;
}
.baseright{
  font-size: 10px;
  min-width: 100px;
  text-align: left;
  margin-top: 8px;
  padding-left: 10px;
  color: #aaaaaa;
}
</style>