<template>
  <header class="header">
    <div class="left-title">云端管理后台</div>
    <div class="middle-title">中国安能集团华东投资开发有限公司</div>
    <div class="bf-button" @click="check">备份</div>
    <el-dialog title="备 份" :visible.sync="dialogVisible">
      <el-row>
        <el-progress
            style="top: 10px; margin: 0 auto 20px 0"
            type="circle"
            :percentage="totalFiles?((exportedFiles/totalFiles)*100).toFixed(2):0">
        </el-progress>
      </el-row>

      <div style="display: flex;justify-content: center;margin-top: 20px;">

        <el-button style="" @click="exportZip" :disabled="isExporting">{{ isExporting ? '导出备份中...' : '导出备份' }}</el-button>

        <el-button @click="download" style="margin-right: 10px;" :disabled="isExporting">下载备份</el-button>
        <el-upload
            :action="uploadUrl"
            :show-file-list="false"
        >
          <el-button >导入备份</el-button>
        </el-upload>
<!--        {{uploadUrl}}-->
      </div>
      <br>
<!--      {{res}}-->
<!--      <el-button @click="isExporting = !isExporting">测试备份</el-button>-->
<!--      <el-button @click="getProgress">测试进度</el-button>-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <div class="user">admin</div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isExporting: false,
      isFinished:false,
      dialogVisible: false,
      percentage:70,
      exportedFiles:0,
      totalFiles:0,
      res:'',
      uploadUrl:'http://192.168.63.134/zip/upload/'+window.localStorage.getItem('token')+'/'
    }
  },
  methods:{
    check(){
      this.dialogVisible = true
      //Todo: isBackingUp赋值, percentage赋初值
      this.getProgress(false)
      // this.axios({
      //   url: 'http://192.168.63.134/zip/check',
      //   method: "get",
      //   params:{
      //     token:window.localStorage.getItem('token')
      //   }
      // }).then((res) => {
      //   console.log("res->:", res);
      //   this.res = res.data;
      //   this.exportedFiles = res.data.exportedFiles
      //   this.totalFiles = res.data.totalFiles
      //   this.isExporting = res.data.isExporting
      //   // this.dialogVisible = true
      // }).catch(err=>{
      //   console.log(err,'dcy')
      // });
    },
    getProgress(showAlert=true){
      let timer = setInterval(()=>{
        this.axios.get("/zip/check").then(res=>{
          console.log(res)
          this.exportedFiles = res.data.exportedFiles
          this.totalFiles = res.data.totalFiles
          this.isExporting = res.data.isExporting
          if (!this.isExporting){
            clearInterval(timer)
            if(showAlert){
              alert('导出完成')
            }
          }
        })
      },2000)
    },
    exportZip(){
      this.axios({
        url: '/zip/export',
        method: "get",
        params:{
          token:window.localStorage.getItem('token')
        }
      }).then((res) => {
        console.log("res->:", res);
        this.res = res.data;
        // this.exportedFiles = 0
        // this.totalFiles = 0
      }).catch(err=>{
        console.log(err,'dcy')
      });
      this.getProgress(true)
    },
    download(){
      window.open("/zip/download")
    }
  }
}
</script>

<style scoped>
.header{
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);
  height: 50px;
  width: 100%;
}
.user{
  float: right;
  text-align: center;
  width: 70px;
  border-left: 1px solid rgba(0, 0, 0, 0.33);
  line-height: 50px;
}
.bf-button:hover{
  cursor: pointer;
}
.bf-button{
  float: right;
  text-align: center;
  width: 70px;
  border-left: 1px solid rgba(0, 0, 0, 0.33);
  line-height: 50px;
}
.left-title{
  float: left;
  text-align: center;
  width: 200px;
  border-right: 1px solid rgba(0, 0, 0, 0.33);
  line-height: 50px
}
.middle-title{
  /*float: left;*/
  text-align: center;
  width: calc(100% - 270px);
  /*border-right: 1px solid rgba(0, 0, 0, 0.33);*/
  line-height: 50px
}
</style>
