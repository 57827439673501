<template>
<div>
  <div style="max-width: 1000px;margin: auto">
    <div style="width: 90%; height: 50px; margin: auto">
      <div  style="float: right">
        <el-button @click="handleAdd()">添加</el-button>
      </div>
    </div>
    <el-table :data="video_list">
      <el-table-column prop="videoId" label="ID" width="50"></el-table-column>
      <el-table-column prop="weight" label="权重" sortable width="80"></el-table-column>
      <el-table-column prop="cover" label="封面">
        <template slot-scope="scope">
          <img :src="scope.row.cover" style="max-height: 70px;object-fit: cover;"/>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import axios from "axios";

export default {
  name: "VideoListEdit",
  data(){
    return{
      list:[
        {
          id:1,
          title:'123',
          weight:0,
          cover:'http://192.168.63.134:8000/static/cover/2022/07/29/1_RwBtaZi.jpg'
        }
      ]
    }
  },
  computed:{
    ...mapState([
        'video_list'
    ]),
  },
  methods:{
    ...mapMutations([
        'setVideoEditing',
        'setTabsValue',
        'setAdminState',
        'updateVideoList',
    ]),
    handleAdd() {
      // console.log('adding', index, row);
      this.setTabsValue('add');
      this.setAdminState('videoEditing');
    },
    handleEdit(index, row){
      // console.log('去编辑',item)
      // this.$router.push('/videoEdit?id='+item.videoId)
      console.log('editing', index, row);
      this.setVideoEditing(row);
      this.setTabsValue('edit');
      this.setAdminState('videoEditing');
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url:"/api/video/admin/edit/"+row.videoId,
          method:"delete",
        }).then((res)=>{
          console.log('delete', res);
          this.updateVideoList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  mounted() {
    this.axios.get('/api/video/admin/list/').then(res=>{
      this.list = res.data;
    })
  }
}
</script>

<style scoped>

</style>