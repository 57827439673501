<template>
<div>
  <div class="basesetting">
    <div v-if="isDownloading" style="color: #aaaaaa;">
      <i class="el-icon-loading" style="font-size: 120px;margin-top: 50px;margin-bottom: 30px;"></i>
      <div>&nbsp;&nbsp;&nbsp;加载中，请稍候.....</div>
    </div>
    <div v-if="isDownloading === false">
      <div class="baseitem" >
        <div class="baseleft">标题：</div>
        <el-input class="basemid" v-model="doc.title"></el-input>
      </div>
      <div class="baseitem" >
        <div class="baseleft">来源：</div>
        <el-input style="max-width: 360px;" v-model="doc.editor"></el-input>

        <div class="baseleft" style="margin-left: 70px;">分类：</div>
        <el-cascader
            v-model="doc.category"
            :options="categoryOptions"
            :props="{ expandTrigger: 'hover' }"></el-cascader>
      </div>
      <div class="baseitem">
        <div class="baseleft">封面：</div>
        <div>

          <div style="width: 360px;height: 270px;margin: 15px 0;">
            <img :src="doc.cover" style="object-fit: cover;width: 100%;height: 100%;"/>
          </div>
          <el-upload
              class="upload-demo"
              :file-list="file_list"
              :limit="1"
              action="#"
              :auto-upload="false"
              :on-change="handleChange">
            <el-button slot="trigger" v-if="doc.cover" style="margin-right: 5px" size="small" type="primary">更换封面</el-button>
            <el-button slot="trigger" v-else style="margin-right: 5px" size="small" type="primary">上传封面</el-button>
            <el-button size="small" v-if="!isDelCover" @click="isDelCover = true">删除封面</el-button>
            <el-button size="small" v-if="isDelCover" @click="isDelCover = false">取消删除</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </div>

        <div class="baseleft" style="margin-left: 70px;">权重：</div>
        <el-input style="max-width: 220px;" v-model="doc.weight"></el-input>

      </div>
      <div class="baseitem" style="margin-top: 25px;">
        <div class="baseleft">发布时间：</div>
        <el-date-picker
            v-model="doc.pub_date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-ddTHH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
        </el-date-picker>
      </div>
      <div class="baseitem">
        <div class="baseleft">类别：</div>
        <div style="margin-top: 10px;">
          <el-radio v-model="showLink" :label="1">正文</el-radio>
          <el-radio v-model="showLink" :label="2">外链</el-radio>
        </div>
      </div>
      <div v-if="showLink == 1">
        <div class="baseitem">
          <div class="baseleft">正文：</div>
        </div>
        <TinyMCE v-model="doc.body_content" :edit_bar_show="true"></TinyMCE>
      </div>
      <div v-if="showLink == 2">
        <div class="baseitem">
          <div class="baseleft">外链：</div>
          <el-input class="basemid" v-model="doc.external_link"></el-input>
        </div>
      </div>
      <el-button type="primary" style="margin: 20px 40px;" :loading="isUploading" @click="save()">保存</el-button>
      <el-button type="plain" style="margin: 20px 40px;" @click="returnList('articleList')">取消</el-button>
      <div v-if="isUploading" style="font-size: 14px;color: red">正在上传中，请勿刷新或离开此页面</div>
    </div>
  </div>

</div>
</template>

<script>
import TinyMCE from "@/components/TinyMCE";
import {mapMutations, mapState} from "vuex";
export default {
  name: "ArticleEdit",
  components: {TinyMCE},
  props:{
    edit:{
      type:Boolean,
      default:false,
    }
  },
  computed:{
    ...mapState([
        'article_editing',
        'section_now',
    ])
  },
  data(){
    return{
      msg:"",
      doc:{
        title:'',
        category:'',
        weight:0,
        body:'',
        body_content:'',
        editor:'',
        cover:'',
        external_link:'',
        pub_date:'',
      },
      isUploading:false, // 保存或创建
      isDownloading:true, // 加载页面
      showLink:1,
      file_list: [],
      doc_cover:'',
      isDelCover:false,
      categoryOptions:[],
    }
  },
  methods:{
    ...mapMutations([
        'updateArticleList',
        'setAdminState',
        'setTabsValue',
    ]),
    handleChange(file, fileList){
      // console.log('bug', this.doc_cover);
      // this.doc_cover = file.raw;
      this.file_list = fileList;
      // console.log('bug', this.doc_cover);
      console.log('fl', this.file_list);
      // this.file_list.push(file.raw);
    },
    deleteImg(){
      this.doc_cover = null;
      this.file_list = [];
    },
    save(){
      console.log('file', this.file_list);

      const formData = new FormData()
      formData.append('title', this.doc.title);
      formData.append('editor',this.doc.editor);
      formData.append('category', this.doc.category[1]);
      formData.append('weight',this.doc.weight);

      if (this.doc.body_content) {
        let fileContent = new File([this.doc.body_content], 'body.txt', { type: '' })
        formData.append('body_file',fileContent);
      }
      if(this.doc.pub_date)
        formData.append('pub_date',this.doc.pub_date);
      if(this.showLink == 2)
        formData.append('external_link',this.doc.external_link);
      console.log('cocc', this.doc_cover)
      if (this.isDelCover){
        // 删除封面
        formData.append('cover', '');
      } else if (this.file_list.length > 0) {
        formData.append('cover', this.file_list[0].raw);
      }
      console.log(this.doc)
      console.log(this.file_list)

      if(this.doc.title == ''){
        this.$notify.error({
          title: '错误',
          message: '标题不能为空'
        });
        return;
      }
      if(this.doc.editor == ''){
        this.$notify.error({
          title: '错误',
          message: '作者不能为空'
        });
        return;
      }

      if(this.doc.category == ''){
        this.$notify.error({
          title: '错误',
          message: '分类不能为空'
        });
        return;
      }

      this.isUploading = true;
      if (this.edit) {
        this.axios({
          url: '/api/document/admin/article/edit/'+this.article_editing.articleId+'/',
          method: "put",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.isUploading = false;
          console.log("res->:", res);
          this.doc = res.data
          this.doc_cover = null;
          this.updateArticleList(this.section_now);
          this.setAdminState('main');
          this.setTabsValue('articleList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      } else {
        this.axios({
          url: '/api/document/admin/article/list/',
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.isUploading = false;
          console.log("res->:", res);
          this.doc = res.data
          this.doc_cover = null;
          this.updateArticleList(this.section_now);
          this.setAdminState('main');
          this.setTabsValue('articleList');
        })
      }
    },
    returnList(tabsValue){
      this.setAdminState('main');
      this.setTabsValue(tabsValue)
    },
    updateCategory(categoryId,sectionId){
      for(let section of this.categoryOptions){
        for(let category of section.children){
          if((categoryId == null && section.value == sectionId) || category.value == categoryId){
            this.doc.category = [section.value,category.value]
          }
        }
      }
    }
  },
  mounted() {
    // todo 根据id获取文章
    this.isDownloading = true;
    this.axios.get('/api/document/admin/section/list/').then(res=>{
      this.categoryOptions = []
      for(let section of res.data){
        let option = {label:section.title,value:section.sectionId}
        option.children = []
        for(let category of section.category){
          option.children.push({label:category.title,value:category.categoryId})
        }
        this.categoryOptions.push(option)
      }
      if(this.edit){ //
        this.axios.get('/api/document/admin/article/edit/'+this.article_editing.articleId+'/')
            .then(res=>{
              this.doc = res.data
              if(this.doc.external_link === null || this.doc.external_link === ''){
                this.showLink = 1
              }else{
                this.showLink = 2
              }
              console.log(res.data)
              this.isDownloading = false;
              this.updateCategory(res.data.category.categoryId,null)
            })
      }else{
        this.updateCategory(null,this.section_now.sectionId)
        this.isDownloading = false;
      }
    })


  }
}
</script>

<style scoped>
.basesetting{
  /*border: solid 1px red;*/
  max-width: 1000px;
  margin: 10px auto;
}
.baseitem{
  margin-bottom: 15px;
  display: flex;
}
.baseleft{
  text-align: right;
  min-width: 50px;
  margin-top: 8px;
  margin-right: 5px;
}
.basemid{
  max-width: 705px;
}
.baseright{
  min-width: 100px;
  text-align: left;
  margin-top: 8px;
  padding-left: 10px;
  color: #aaaaaa;
}
</style>
