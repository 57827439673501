<template>
  <div>
    <div style="max-width: 1000px;margin: auto">
      <div style="width: 90%; height: 50px; margin: auto">
        <div  style="float: right">
          <el-button @click="handleAdd()">添加</el-button>
        </div>
      </div>
      <el-table :data="banner_list">
        <el-table-column prop="bannerId" label="ID" width="50"></el-table-column>
        <el-table-column prop="weight" label="权重" sortable width="80"></el-table-column>
        <el-table-column prop="banner" label="图片">
          <template slot-scope="scope">
            <img :src="scope.row.banner" style="max-height: 70px;object-fit: cover;"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import axios from "axios";

export default {
  name: "BannerList",
  data(){
    return {
    }
  },
  computed:{
    ...mapState([
      'banner_list'
    ]),
  },
  methods:{
    ...mapMutations([
      'setBannerEditing',
      'setTabsValue',
      'setAdminState',
      'updateBannerList',
    ]),
    handleAdd() {
      // console.log('adding', index, row);
      this.setTabsValue('add');
      this.setAdminState('bannerEditing');
    },
    handleEdit(index, row){
      // console.log('去编辑',item)
      // this.$router.push('/videoEdit?id='+item.videoId)
      console.log('editing', index, row);
      this.setBannerEditing(row);
      this.setTabsValue('edit');
      this.setAdminState('bannerEditing');
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将永久删除该首页横幅, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url:"/api/document/admin/banner/edit/"+row.bannerId,
          method:"delete",
        }).then((res)=>{
          console.log('delete', res);
          this.updateBannerList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
}
</script>

<style scoped>

</style>