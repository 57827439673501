<template>
  <div>
    <div class="basesetting">
      <div>
        <div class="baseitem" >
          <div class="baseleft">标题：</div>
          <el-input class="basemid" v-model="doc.title"></el-input>
          <div class="baseright">说明说明</div>
        </div>
        <div class="baseitem" >
          <div class="baseleft" style="margin-left: 70px;">权重：</div>
          <el-input style="max-width: 220px;" v-model="doc.weight"></el-input>
          <div class="baseright">说明说明</div>
        </div>
        <div class="baseitem">
          <div class="baseleft">横幅：</div>
          <div>

            <div style="width: 600px;height: 180px;margin: 15px 0;">
              <img :src="doc.banner" style="object-fit: cover;width: 100%;height: 100%;"/>
            </div>
            <el-upload
                class="upload-demo"
                :file-list="file_list"
                action="#"
                :auto-upload="false"
                :on-change="handleChange">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </div>

        </div>

        <el-button type="primary" style="margin: 20px 40px;" @click="save()">保存</el-button>
        <el-button type="plain" style="margin: 20px 40px;"  @click="returnList('categoryList')">取消</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import TinyMCE from "@/components/TinyMCE";
import {mapMutations, mapState} from "vuex";
export default {
  name: "CategoryEdit",
  components: {TinyMCE},
  props:{
    edit:{
      type:Boolean,
      default:false,
    }
  },
  computed:{
    ...mapState([
        'category_editing',
        'section_now'
    ])
  },
  data(){
    return{
      msg:"",
      doc:{
        title:'',
        section:'',
        weight:0,
        editor:'',
      },
      doc_null:{
        title:'',
        section:'',
        weight:0,
        editor:'',
      },
      file_list: [],
      doc_banner:null,
      categoryOptions:[],
    }
  },
  mounted() {
    if (this.edit){
      this.doc = this.category_editing
    }
  },
  methods:{
    ...mapMutations([
        'updateCategoryList',
        'setAdminState',
        'setTabsValue',
    ]),
    handleChange(file){
      console.log(file);
      this.doc_banner = file.raw;
      this.file_list.push(file.raw);
    },
    returnList(tabsValue){
      this.setAdminState('main');
      this.setTabsValue(tabsValue)
    },
    save(){
      console.log('file', this.section_now);

      const formData = new FormData()
      formData.append('title', this.doc.title);
      formData.append('section', this.section_now.sectionId);
      formData.append('weight',this.doc.weight);
      formData.append('body',this.doc.body);
      console.log(this.doc_banner)
      if (this.doc_banner){
        formData.append('banner', this.doc_banner);
      }
      console.log(this.doc)
      if (this.edit) {
        this.axios({
          url: '/api/document/admin/category/edit/'+this.category_editing.categoryId+'/',
          method: "put",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.doc =  this.doc_null;//res.data
          this.doc_banner = null;
          this.updateCategoryList(this.section_now);
          this.setAdminState('main');
          this.setTabsValue('categoryList');
        });
      } else {
        this.axios({
          url: '/api/document/admin/category/list/',
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.doc = this.doc_null;//res.data
          this.doc_banner = null;
          this.updateCategoryList(this.section_now);
          this.setAdminState('main');
          this.setTabsValue('categoryList');
        });
      }
    }
  },
}
</script>

<style scoped>
.basesetting{
  /*border: solid 1px red;*/
  max-width: 1000px;
  margin: 10px auto;
}
.baseitem{
  margin-bottom: 15px;
  display: flex;
}
.baseleft{
  text-align: right;
  min-width: 50px;
  margin-top: 8px;
  margin-right: 5px;
}
.basemid{
  max-width: 705px;
}
.baseright{
  min-width: 100px;
  text-align: left;
  margin-top: 8px;
  padding-left: 10px;
  color: #aaaaaa;
}
</style>