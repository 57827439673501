<template>
  <div>
    <div style="width: 90%; height: 50px; margin: auto">
      <div style="float: left">
        <el-button
            v-if="multipleSelection.length>0"
            @click="$refs.multipleTable.clearSelection(); handleButtonClick($event)"
        >取消选择
        </el-button>

      </div>
      <div  style="float: right">
        <el-button @click="handleAdd()">添加</el-button>
        <el-button @click="multipleDelete(); handleButtonClick($event)">批量删除</el-button>
      </div>
    </div>
    <el-table
        ref="multipleTable"
        :data="article_list"
        tooltip-effect="dark"
        border
        style="width: 90%; margin: auto"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="60"
      >
      </el-table-column>
      <el-table-column
          prop="articleId"
          label="ID"
          width="60"
          sortable
          >
        <!--          <template slot-scope="scope">{{ scope.row.date }}</template>-->
      </el-table-column>
      <el-table-column
          prop="weight"
          label="排序"
          width="80"
          sortable
          >
      </el-table-column>
      <el-table-column
          label="显示状态"
          width="120"
          >
        <template slot-scope="scope">
          <el-tag
              v-if="scope.row.status_display"
              type="success"
          >
            已显示
          </el-tag>
          <el-tag
              v-else
              type="info"
          >
            未显示
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题"

          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="category.title"
          label="分类"
          :filters="category_filter"
          :filter-method="filterCategory"
          >
      </el-table-column>
      <el-table-column
          prop="pub_date"
          label="创建时间"
          sortable
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import axios from "axios";

export default {
  name: "ArticleList",
  data() {
    return {
      // articles:[],
      multipleSelection: [],
    }
  },
  computed: {
    ...mapState([
        'article_list',
        'section_now',
        'category_list',
        'category_filter',
    ])
  },
  watch: {
    'category_filter':{
      handler: function () {
        this.$refs.multipleTable.clearFilter();
      }
    }
  },
  methods: {
    ...mapMutations([
        'updateArticleList',
        'setTabsValue',
        "setArticleEditing",
        "setAdminState",
    ]),
    ...mapActions([
    ]),
    filterCategory(value, row, column) {
      return row.category.title === value;
    },
    handleButtonClick (e) {
      console.log('e', e);
      let target = e.target;
      // 根据button组件内容 里面包括一个span标签，如果设置icon，则还包括一个i标签，其他情况请自行观察。
      // 所以，在我们点击到button组件上的文字也就是span标签上时，直接执行e.target.blur()不会生效，所以要加一层判断。
      if(target.nodeName === 'SPAN' || target.nodeName === 'I'){
        target = e.target.parentNode;
      }
      target.blur();
    },
    multipleDelete(){
      console.log(this.multipleSelection);
      let del_list = this.multipleSelection.map((item, index)=>{
        return item.articleId
      })
      console.log(del_list)
      this.$confirm('此操作将永久删除所选中的全部文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url:"/api/document/admin/article/delList/",
          method:"delete",
          data:{
            del_list,
          }
        }).then((res)=>{
          console.log('delete', res);
          this.updateArticleList(this.section_now);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(index, row) {
      console.log('editing', index, row);
      this.setArticleEditing(row);
      this.setTabsValue('edit');
      this.setAdminState('articleEditing');
    },
    handleAdd(index, row) {
      console.log('adding', index, row);
      this.setTabsValue('add');
      this.setAdminState('articleEditing');
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url:"/api/document/admin/article/edit/"+row.articleId,
          method:"delete",
        }).then((res)=>{
          console.log('delete', res);
          this.updateArticleList(this.section_now);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
