import { render, staticRenderFns } from "./VideoListEdit.vue?vue&type=template&id=c4c80470&scoped=true&"
import script from "./VideoListEdit.vue?vue&type=script&lang=js&"
export * from "./VideoListEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4c80470",
  null
  
)

export default component.exports