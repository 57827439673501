<template>
  <div>
    <div class="basesetting">

      <div class="baseitem">
        <div class="baseleft">横幅：</div>
        <div>
          <div style="margin: 15px 0;">
            <img :src="banner.banner" style="object-fit: cover;width: 100%;height: 100%;"/>
          </div>
          <el-upload
              class="upload-demo"
              :file-list="file_list"
              :limit="1"
              action="#"
              :auto-upload="false"
              :on-change="handleChange">
            <el-button slot="trigger" v-if="banner.banner" style="margin-right: 5px" size="small" type="primary">更换横幅</el-button>
            <el-button slot="trigger" v-else style="margin-right: 5px" size="small" type="primary">上传横幅</el-button>
            <el-button size="small" v-if="!isDelBanner" @click="isDelBanner = true">删除横幅</el-button>
            <el-button size="small" v-if="isDelBanner" @click="isDelBanner = false">取消删除</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </div>

        <div class="baseleft" style="margin-left: 70px;">权重：</div>
        <el-input style="max-width: 220px;" v-model="banner.weight"></el-input>
      </div>
      <div>
        <el-button @click="save" type="primary" style="margin-right: 100px;margin-top: 50px;">保存</el-button>
        <el-button @click="setAdminState('bannerList'); setTabsValue('bannerList')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "BannerEdit",
  props:{
    edit:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      banner: {},
      file_list: [],
      isDelBanner: false,
    }
  },
  computed:{
    ...mapState([
      'banner_editing'
    ]),
  },
  methods:{
    ...mapMutations([
      'setBannerEditing',
      'setTabsValue',
      'setAdminState',
      'updateBannerList'
    ]),
    save(){
      console.log('file', this.file_list);

      const formData = new FormData()
      formData.append('weight',this.banner.weight);

      if (this.isDelBanner){
        // 删除图片
        formData.append('banner', '');
      } else if (this.file_list.length > 0) {
        formData.append('banner', this.file_list[0].raw);
      }
      console.log(this.banner)
      console.log(this.file_list)

      if (this.edit) {
        this.axios({
          url: '/api/document/admin/banner/edit/'+this.banner.bannerId+'/',
          method: "put",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateBannerList();
          this.setAdminState('bannerList');
          this.setTabsValue('bannerList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      } else {
        this.axios({
          url: '/api/document/admin/banner/list/',
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateBannerList();
          this.setAdminState('bannerList');
          this.setTabsValue('bannerList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      }
    },
    handleChange(file, fileList){
      // console.log('bug', this.doc_cover);
      // this.doc_cover = file.raw;
      this.file_list = fileList;
      // console.log('bug', this.doc_cover);
      console.log('fl', this.file_list);
      // this.file_list.push(file.raw);
    },
  },
  mounted() {
    // this.$axios.get('/video/admin/view/'+this.$route.query.id).then(res=>{
    //   console.log(res)
    // })
    if(this.edit){ //
      this.axios.get(
          '/api/document/admin/banner/edit/'+this.banner_editing.bannerId+'/'
      ).then(res=>{
        this.banner = res.data
      })
    }
  }
}
</script>

<style scoped>
.basesetting{
  /*border: solid 1px red;*/
  max-width: 1000px;
  margin: 10px auto;
}
.baseitem{
  margin-bottom: 15px;
  display: flex;
}
.baseleft{
  text-align: right;
  min-width: 50px;
  margin-top: 8px;
  margin-right: 5px;
}
.basemid{
  max-width: 705px;
}
.baseright{
  min-width: 100px;
  text-align: left;
  margin-top: 8px;
  padding-left: 10px;
  color: #aaaaaa;
}
</style>